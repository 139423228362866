import React, { useState } from 'react';
import css from './Menu.module.css';
import vocacionamiento from "../../assets/vocacionamiento.png"
import admision from "../../assets/admision.png"
import logoTecmi  from '../../assets/logo.png'
import beca from "../../assets/beca.png"
import inteligencia from "../../assets/inteligencia.png"
import onOff  from "../../assets/onOff.png"
import { useAuth } from '../../contexts/AuthContext';

import { LeftArrow } from '../../assets/icons';

const SideMenu = () => {

    const { user, handleLogout } = useAuth();
    
    const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);

    const toggleMenu = () => {
        setIsMenuCollapsed(!isMenuCollapsed);
    };

    const logOut = () =>{
        handleLogout();
    }
    
    return (
        <div className={isMenuCollapsed ? css.menu__collapsed : css.menu__container}>
            <div>
            <div className={css.menu__header}>
                <img src={logoTecmi} alt="Tecmilenio Logo" className={css.menu__logo} onClick={toggleMenu}/>
            </div>

            <div className={css.menu__content}>
                {!isMenuCollapsed && <h4 className={css.menu__title}>Exámenes</h4>}
                <ul className={css.menu__list}>
                    <li className={css.menu__listItem}>
                        <a href="/exam">
                        <img src={vocacionamiento} alt="icono" className={css.icon} />
                        {!isMenuCollapsed && 'Vocacionamiento'}
                        </a>
                    </li>
                    <li className={css.menu__listItem}>
                    <a href="/admision">
                    <img src={admision} alt="icono" className={css.icon} />
                        {!isMenuCollapsed && 'Admisión'}
                    </a>
                    </li>
                    <li className={css.menu__listItem}>
                    <a href="/admision-chat">
                    <img src={admision} alt="icono" className={css.icon} />
                        {!isMenuCollapsed && 'Admisión Chat'}
                    </a>
                    </li>
                    <li className={css.menu__listItem}>
                    <img src={beca} alt="icono" className={css.icon} />
                        {!isMenuCollapsed && 'Beca'}
                    </li>
                    <li className={css.menu__listItem}>
                    <img src={inteligencia} alt="icono" className={css.icon} />
                        {!isMenuCollapsed && 'Inteligencia emocional'}
                    </li>
                </ul>
            </div>
            </div>

            <div className={css.menu__footer }>
                <button className={css.menu__hideButton} onClick={toggleMenu}>
                    {!isMenuCollapsed ? 
                        <><LeftArrow className={css.arrow_icon} /> <span>Esconder</span></>
                    : (
                        <><LeftArrow className={css.arrow_icon} type="right"/> </>
                    )}
                </button>
                <div className={css.menu__userInfo}>
                    <div className={css.menu__avatar}>
                        {user?.name?.charAt(0)}{user?.last_name?.charAt(0)}
                    </div>
                    <div className={css.menu__userDetails}>
                        <span className={css.menu__userName}>
                            {user?.name} {user?.last_name}
                        </span>
                        <span className={css.menu__userEmail}>
                            {user?.email}
                        </span>
                    </div>
                </div>
                <button onClick={e => logOut()} className={css.menu__logoutButton}>
                <img src={onOff} alt="icono" className={css.icon} />
                    {!isMenuCollapsed && 'Cerrar sesión'}
                </button>
            </div>
        </div >
    );
};

export default SideMenu;
