import { Navigate } from 'react-router-dom';
import { Menu } from './components/index';
import {
    HomePage,
    LoginPage,
    ExamPage,
    AdmisionPage,
    ResultPage,
    ProfilePage,
    AdmisionChatbotPage
} from './containers';

import { useAuth } from "./contexts/AuthContext";

const PrivateRoute = ({ element }) => {
    const { error } = useAuth();
   
    if(!error){
        return element
    }else {
        return <Navigate to="/login" />
    }
};

export const routes = [
    {
        path: '/',
        element: <PrivateRoute element={<HomePage />} />,
    },
    {
        path: '/login',
        element: <LoginPage />,
    },
    {
        path: '/profile',
        element: <PrivateRoute element={<ProfilePage />} />,
    },
    {
        path: '/exam',
        element: <PrivateRoute element={<ExamPage />} />,
    },
    {
        path: '/admision',
        element: <PrivateRoute element={<AdmisionPage />} />,
    },
    {
        path: '/admision-chat',
        element: <PrivateRoute element={<AdmisionChatbotPage />} />,
    },
    {
        path: '/menu',
        element: <PrivateRoute element={<Menu />} />,
    },
    {
        path: '/result',
        element: <PrivateRoute element={<ResultPage />} />,
    }
];
